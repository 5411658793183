<template>
    <div id="app">
        <div class="page-wrapper default-theme sidebar-bg bg1 toggled">
            <nav id="sidebar" :class="['sidebar-wrapper', {'nav_open': this.open, 'nav_close': this.close},]">
                <div class="sidebar-content">
                    <!-- sidebar-brand  -->
                    <!-- <div class="sidebar-item sidebar-brand" style="background: #ffff;">
                        
                    </div> -->
                    <!-- sidebar-header  -->
                    <div class="sidebar-item sidebar-header d-flex flex-nowrap">
                        <div class="user-pic">
                           <img class="img-responsive img-rounded" style="width: 80%;"
                                src="../assets/profile.png">
                        </div>
                        <div class="user-info">
                            <span class="user-name">
                                <strong>{{name}}</strong>
                            </span>
                            <span class="user-role text-capitalize">{{role}}</span>
                        </div>
                    </div>
                    <div class=" sidebar-item sidebar-menu">
                        <ul>
                            <li v-if="checkPermission('dashboardShow') == true" style="margin-top: 12px;">
                                <router-link :class="[{active: url === 'dashboard'}]" to="/dashboard">
                                    <i class="fas fa-tachometer-alt"></i>
                                    <span class="menu-text">Dashboard</span>
                                </router-link>
                            </li>
                            <li v-if="checkPermission('accountShow') == true">
                                <router-link :class="[{active: url === 'account-list' || url === 'account-create' ||  url === 'account-edit'}]" :to="{ path: 'account-list', query: { page: 1, reverse: 'desc', sort: 'created_at', name: ''}}">
                                    <i class="fas fa-user"></i>
                                    <span class="menu-text">Account</span>
                                </router-link>
                            </li>
                            <li v-if="checkPermission('accountShow') == true">
                                <router-link :class="[{active: url === 'member-list' || url === 'member-create' ||  url === 'member-edit'}]" :to="{ path: 'member-list', query: { page: 1, reverse: 'desc', sort: 'created_at', name: ''}}">
                                    <i class="fas fa-user"></i>
                                    <span class="menu-text">User</span>
                                </router-link>
                            </li>
                            <li  v-if="checkPermission('subscriberShow') == true">
                                <router-link :class="[{active: url === 'subscriber-list'}]" :to="{ path: 'subscriber-list', query: { page: 1, reverse: 'desc', sort: 'created_at', name: ''}}">
                                    <i class="fas fa-user"></i>
                                    <span class="menu-text">Subscriber</span>
                                </router-link>
                            </li>
                            <li  v-if="checkPermission('bannerShow') == true">
                                <router-link :class="[{active: url === 'banner-list' || url === 'banner-create' || url === 'banner-edit'}]" :to="{ path: 'banner-list', query: { page: 1, reverse: 'desc', sort: 'created_at'}}">
                                    <i class="fas fa-images"></i>
                                    <span class="menu-text">Banner</span>
                                </router-link>
                            </li>
                            <li  v-if="checkPermission('merchantShow') == true">
                                <router-link :class="[{active: url === 'merchant-list' || url === 'merchant-create' || url === 'merchant-edit'}]" :to="{ path: 'merchant-list', query: { page: 1, reverse: 'desc', sort: 'created_at', name: '', phone: '', shop: ''}}">
                                    <i class="fas fa-house-user"></i>
                                    <span class="menu-text">Merchant</span>
                                </router-link>
                            </li>
                            <li  v-if="checkPermission('contractShow') == true">
                                <router-link :class="[{active: url === 'contract-list' || url === 'contract-create' || url === 'contract-edit'}]" :to="{ path: 'contract-list', query: { page: 1, reverse: 'desc', sort: 'created_at', name: '', phone: '', shop: ''}}">
                                    <i class="fas fa-house-user"></i>
                                    <span class="menu-text">Contract</span>
                                </router-link>
                            </li>
                            <li v-if="checkPermission('shopShow') == true">
                                <router-link :class="[{active: url === 'shop-list' || url === 'shop-create' || url === 'shop-edit'}]" :to="{ path: 'shop-list', query: { page: 1, reverse: 'desc', sort: 'created_at', name: '', phone: '', address: ''}}">
                                    <i class="fas fa-store"></i>
                                    <span class="menu-text">Shop</span>
                                </router-link>
                            </li>
                            <li  v-if="checkPermission('pointShow') == true">
                                <router-link :class="[{active: url === 'point-list' || url === 'point-create'}]" :to="{ path: 'point-list', query: { page: 1, reverse: 'desc', sort: 'created_at'}}">
                                    <i class="fas fa-store"></i>
                                    <span class="menu-text">Point Manage</span>
                                </router-link>
                            </li>
                            <li  v-if="checkPermission('categoryShow') == true">
                                <router-link :class="[{active: url === 'category-list' || url === 'category-create' || url === 'category-edit'}]" :to="{ path: 'category-list', query: { page: 1, reverse: 'desc', sort: 'created_at', name: ''}}">
                                    <i class="fas fa-sitemap"></i>
                                    <span class="menu-text">Category</span>
                                </router-link>
                            </li>
                            <!-- <li v-if="role == 'admin'">
                                <router-link :class="[{active: url === 'product-draft-list'}]" :to="{ path: 'product-draft-list', query: { page: 1, reverse: 'desc', sort: 'created_at', name: '', status: 'draft'}}">
                                    <i class="fas fa-pump-soap"></i>
                                    <span class="menu-text">Draft Product</span>
                                </router-link>
                            </li> -->
                            <li  v-if="checkPermission('productPendingShow') == true">
                                <router-link :class="[{active: url === 'product-pending-list'}]" :to="{ path: 'product-pending-list', query: { page: 1, reverse: 'desc', sort: 'created_at', shopId: '', name: '', status: 'pending'}}">
                                    <i class="fas fa-pump-soap"></i>
                                    <span class="menu-text">Pending <span class="badge badge-danger ml-2">{{productCount}}</span></span>
                                </router-link>
                            </li>
                            <li  v-if="checkPermission('productActiveShow') == true">
                                <router-link :class="[{active: url === 'product-active-list'}]" :to="{ path: 'product-active-list', query: { page: 1, reverse: 'desc', sort: 'created_at', shopId: '', name: '', isExpire: '', status: 'active'}}">
                                    <i class="fas fa-pump-soap"></i>
                                    <span class="menu-text">Active </span>
                                </router-link>
                            </li>
                            <li  v-if="checkPermission('productInactiveShow') == true">
                                <router-link :class="[{active: url === 'product-inactive-list'}]" :to="{ path: 'product-inactive-list', query: { page: 1, reverse: 'desc', sort: 'created_at', shopId: '', name: '', status: 'inactive'}}">
                                    <i class="fas fa-pump-soap"></i>
                                    <span class="menu-text">Inactive </span>
                                </router-link>
                            </li>
                            <li  v-if="checkPermission('productRejectShow') == true">
                                <router-link :class="[{active: url === 'product-reject-list'}]" :to="{ path: 'product-reject-list', query: { page: 1, reverse: 'desc', sort: 'created_at', shopId: '', name: '', status : 'reject'}}">
                                    <i class="fas fa-pump-soap"></i>
                                    <span class="menu-text">Reject </span>
                                </router-link>
                            </li>
                            <li  v-if="checkPermission('productAllShow') == true">
                                <router-link :class="[{active: url === 'product-list' || url === 'product-create' || url === 'product-edit'}]" :to="{ path: 'product-list', query: { page: 1, reverse: 'desc', sort: 'created_at', shopId: '', name: '', status: ''}}">
                                    <i class="fas fa-pump-soap"></i>
                                    <span class="menu-text">All Product</span>
                                </router-link>
                            </li>
                            <li  v-if="checkPermission('orderShow') == true">
                                <router-link :class="[{active: url === 'bank-transfer-list'}]" :to="{ path: 'bank-transfer-list', query: { page: 1, reverse: 'desc', sort: 'created_at', shopId: '', fromDate: '', toDate: '', status: ''}}">
                                    <i class="fas fa-hand-holding-usd"></i>
                                    <span class="menu-text">Order<span class="badge badge-danger ml-2">{{transferCount}}</span></span>
                                </router-link>
                            </li>
                             <li  v-if="checkPermission('orderInfoShow') == true">
                                <router-link :class="[{active: url === 'sale-order-detail-list'}]" :to="{ path: 'sale-order-detail-list', query: { page: 1, reverse: 'desc', sort: 'created_at', shopId: '', fromDate: '', toDate: '', status: ''}}">
                                    <i class="fas fa-hand-holding-usd"></i>
                                    <span class="menu-text">Order Detail <span class="badge badge-danger ml-2">{{saleCount}}</span></span>
                                </router-link>
                            </li>
                            <!-- <li>
                                <router-link :class="[{active: url === 'salon-revenue-billing'}]" :to="{ path: 'salon-revenue-billing', query: { page: 1, reverse: 'desc', sort: 'created_at', salonId: '', saleId: '', reportStatus: '', orderStatus: '', isRegister: '', discountType: '', fromDate: '', toDate: '',	reportDate: '',	billingDate: ''}}">
                                    <i class="fas fa-file-invoice"></i>
                                    <span class="menu-text">Salon Revenue</span>
                                </router-link>
                            </li> -->
                        </ul>
                    </div>
                    <!-- sidebar-menu  -->
                </div>
                <!-- sidebar-footer  -->
                <div class="sidebar-footer">
                    <div class="dropdown">
                         <a href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                           <i class="fas fa-bell"></i>
                        </a>
                    </div>
                    <div class="dropdown">
                        <a href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i class="fa fa-user"></i>
                        </a>
                        <div class="dropdown-menu" aria-labelledby="dropdownMenuMessage">
                            <a class="dropdown-item" href="#">My profile</a>
                            <a class="dropdown-item" href="#">Help</a>
                            <a class="dropdown-item" href="#">Setting</a>
                        </div>
                    </div>
                    <div>
                        <a href="#" @click="logout()">
                            <i class="fa fa-power-off"></i>
                        </a>
                    </div>
                    <div class="pinned-footer">
                        <a href="#">
                            <i class="fas fa-ellipsis-h"></i>
                        </a>
                    </div>
                </div>
            </nav>
            <div class="layer" @click="closeNav()" :class="{'layer_open': this.open}"></div>
            <!-- page-content  -->
            <main class="page-content pt-2">
                <label class="icon admin-menu" @click="openNav()" for="check-menu" style="margin-left: 2%;">
                    &#9776;
                </label>
                <!-- <label class="translate">
                    <v-google-translate  @select="googleTranslateSelectedHandler"/>
                </label> -->
                <router-view />
            </main>
        </div>

    </div>
</template>
<script>
    import {
        mapActions
    } from 'vuex'
    export default {
        name: 'admin',
        data() {
            return {
                saleCount: '',
                transferCount: '',
                productCount: '',
                check: true,
                close: true,
                open: false,
                name: '',
                role: '',
                url: '',
                permissions: "",
                languages: [
                    {
                        code: "en",
                        name: "English",
                        cname: "英语",
                        ename: "English",
                    },
                    {
                        code: "af",
                        name: "Afrikaans",
                        cname: "南非语",
                        ename: "Afrikaans",
                    },
                ]
            }
        },
        methods: {
            ...mapActions({
                saleOrderCountAction: 'saleOrderCountAction',
                productPendingCountAction: 'productPendingCountAction'
            }),
            googleTranslateSelectedHandler(language) {
                const { code, name, cname, ename } = language
                // todo ...
                },
            async getSaleCount() {
                await this.saleOrderCountAction().then(res => {
                    if (res.status == "success") {
                        this.saleCount = res.data.data['order']
                        this.transferCount = res.data.data['transfer']
                    }
                }).catch(err => this.isLoading = true)
            },
            async getProductCount() {
                await this.productPendingCountAction().then(res => {
                    if (res.status == "success") {
                        this.productCount = res.data.data
                    }
                }).catch(err => this.isLoading = true)
            },
            openNav: function () {
                this.open = !this.open
                console.log(this.open)
                if (this.check == true) {
                    this.close = false
                    this.open = true
                }
            },
            closeNav: function () {
                this.open = !this.open
                if (this.open == false) {
                    this.close = true
                    this.open = false
                }
            },
            checkPermission: function (key) {
                if(key in this.permissions){
                    return true
                }else{
                    return false
                }
            },
            logout() {
                window.localStorage.setItem('token', null)
                this.$router.replace('/login')
            }
        },
        watch: {
            '$route'(to, from) {
                this.url = to.name
            },
        },
        mounted() {
            let user = JSON.parse(window.localStorage.getItem('user'))
            this.name = user.name
            this.role = user.role
            this.permissions = user.permissions
            this.url = this.$route.name
            this.getSaleCount()
            this.getProductCount()
        }
    }
</script>
<style lang="scss">
    @import "https://stackpath.bootstrapcdn.com/bootstrap/4.1.3/css/bootstrap.min.css";
    @import "https://use.fontawesome.com/releases/v5.8.2/css/all.css";
    @import "../assets/css/main.css";
    @import "../assets/css/sidebar-themes.css";

    ::-webkit-scrollbar {
        width: 6px;
        background-color: #525965;
    }

    .skiptranslate {
    display: none !important;
    }

    .eo__dropdown__menu {
        position: relative !important;
    }

    .translate {
        position: fixed;
        z-index: 100;
        top: 1px;
        right: 3%;
    }

    .float {
        position: fixed;
        width: 60px;
        height: 60px;
        bottom: 50px;
        right: 2%;
        background-color: #0C9;
        color: #FFF;
        border-radius: 50px;
        text-align: center;
        box-shadow: 2px 2px 3px #999;
    }

    .float-loss {
        position: fixed;
        bottom: 50px;
        right: 8%;
        background-color: #007bffC9;
        color: #FFF;
        text-align: center;
        box-shadow: 2px 2px 3px #999;
    }

    .float-excel {
        position: fixed;
        width: 60px;
        height: 60px;
        bottom: 117px;
        right: 2%;
        background-color: #007bff;
        color: #FFF;
        border-radius: 50px;
        text-align: center;
        box-shadow: 2px 2px 3px #999;
    }

    .float-save {
        position: fixed;
        bottom: 50px;
        right: 2%;
        background-color: #007bffC9;
        color: #FFF;
        text-align: center;
        box-shadow: 2px 2px 3px #999;
    }

    .float-filter {
        position: fixed;
        width: 60px;
        height: 60px;
        bottom: 120px;
        right: 2%;
        background-color: #318be3;
        color: #FFF;
        border-radius: 50px;
        text-align: center;
        box-shadow: 2px 2px 3px #999;
    }

    .btn-option {
        border: none;
        background: none;
        font-size: 24px;
    }

    .btn:focus,
    .btn:active:focus,
    .btn.active:focus {
        box-shadow: none !important;
    }

    .modal-backdrop {
        opacity: 0.5 !important;
    }

    /* Add a black background color to the top navigation */
    .topnav {
        background-color: #333;
        overflow: hidden;
    }

    /* Style the links inside the navigation bar */
    .topnav a {
        float: left;
        display: block;
        color: #f2f2f2;
        text-align: center;
        padding: 14px 16px;
        text-decoration: none;
        font-size: 17px;
    }

    /* Change the color of links on hover */
    .topnav a:hover {
        background-color: #ddd;
        color: black;
    }

    /* Add an active class to highlight the current page */
    .topnav a.active {
        background-color: #4CAF50;
        color: white;
    }

    /* Hide the link that should open and close the topnav on small screens */
    .topnav .icon {
        display: none;
    }

    // Table
    .desc {
        background-repeat: no-repeat;
        background-position: right;
        /* width: 1em; height: 1em; */
        /* border-top: 0px; border-bottom: 0px; border-left: 0px; border-right: 0px; */
        opacity: 1;
        /* padding: 3%; */
        background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAATCAYAAAByUDbMAAAAZ0lEQVQ4y2NgGLKgquEuFxBPAGI2ahhWCsS/gDibUoO0gPgxEP8H4ttArEyuQYxAPBdqEAxPBImTY5gjEL9DM+wTENuQahAvEO9DMwiGdwAxOymGJQLxTyD+jgWDxCMZRsEoGAVoAADeemwtPcZI2wAAAABJRU5ErkJggg==');
    }

    .asc {
        background-repeat: no-repeat;
        background-position: right;
        /* width: 1em; height: 1em; */
        /* border-top: 0px; border-bottom: 0px; border-left: 0px; border-right: 0px; */
        opacity: 1;
        /* padding: 3%; */
        background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAATCAYAAAByUDbMAAAAZUlEQVQ4y2NgGAWjYBSggaqGu5FA/BOIv2PBIPFEUgxjB+IdQPwfC94HxLykus4GiD+hGfQOiB3J8SojEE9EM2wuSJzcsFMG4ttQgx4DsRalkZENxL+AuJQaMcsGxBOAmGvopk8AVz1sLZgg0bsAAAAASUVORK5CYII= ');
    }

    .table_info {
        float: left;
    }

    .paging_simple_numbers {
        float: right;
    }

    th {
        text-align: center;
    }

    td {
        text-align: center;
        white-space: nowrap;
    }

    .th-width-50 {
        width: 50px;
    }

    .th-width-100 {
        width: 100px;
    }

    .th-width-200 {
        width: 200px;
    }

    .th-width-300 {
        width: 300px;
    }
    //
    .active {
        color: #20c997 !important;
    }
    //table image
    .tbl_image {
        width: 50px;
        height: 50px;
        background-size: contain;
    }
    //Filter Button
    .fbutton {
        width: 49%;
    }
    //Search
    .search-form {
        display: flex;
        justify-content: flex-end;
    }
    .style-chooser .vs__search::placeholder,
        .style-chooser .vs__dropdown-toggle,
        .style-chooser .vs__dropdown-menu {
        height: 38px;
    }
    /* tootips */
    .customtootip {
        position: relative;
        // display: inline-block;
    }

    .customtootip .customtootiptext {
        visibility: hidden;
        /* width: 120px; */
        background-color: #000000;
        color: #fff;
        text-align: center;
        border-radius: 6px;
        padding: 5px 10px;
        /* Position the customtootip */
        position: absolute;
        z-index: 1;
    }

    .customtootip:hover .customtootiptext {
        visibility: visible;
    }
</style>