import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/auth/Login'
import Admin from '../views/Admin'

Vue.use(VueRouter)

const routes = [{
		path: '/login',
		name: 'login',
		component: Login
	},
	{
		path: '/admin',
		name: 'admin',
		component: Admin,
		children: [{
			path: '/dashboard',
			name: 'dashboard',
			component: () => import('../views/Dashboard'),
		},
		{
			path: '/account-list',
			query: {
				page: 1,
				reverse: '',
				sort: '',
				name: '',
			},
			name: 'account-list',
			component: () => import('../views/account/accountList'),
		},
		{
			path: '/account-create',
			name: 'account-create',
			component: () => import('../views/account/accountCreate'),
		},
		{
			path: '/account-edit',
			query: {
				page: 1,
				reverse: '',
				sort: '',
				name: '',
				id: '',
			},
			name: 'account-edit',
			component: () => import('../views/account/accountEdit'),
		},
		{
			path: '/member-list',
			query: {
				page: 1,
				reverse: '',
				sort: '',
				name: '',
			},
			name: 'member-list',
			component: () => import('../views/member/accountList'),
		},
		{
			path: '/member-create',
			name: 'member-create',
			component: () => import('../views/member/accountCreate'),
		},
		{
			path: '/member-edit',
			query: {
				page: 1,
				reverse: '',
				sort: '',
				name: '',
				id: '',
			},
			name: 'member-edit',
			component: () => import('../views/member/accountEdit'),
		},
		{
			path: '/subscriber-list',
			query: {
				page: 1,
				reverse: '',
				sort: '',
				name: '',
			},
			name: 'subscriber-list',
			component: () => import('../views/subscriber/subscriberList'),
		},
		{
			path: '/banner-list',
			name: 'banner-list',
			query: {
				page: 1,
				reverse: '',
				sort: '',
				name: ''
			  },
			component: () => import('../views/banner/bannerList'),
		},
		{
			path: '/banner-create',
			name: 'banner-create',
			component: () => import('../views/banner/bannerCreate'),
		},
		{
			path: '/banner-edit',
			name: 'banner-edit',
			query: {
				page: 1,
				reverse: '',
				sort: '',
				name: ''
			  },
			component: () => import('../views/banner/bannerEdit'),
		},
		{
			path: '/banner-detail',
			name: 'banner-detail',
			query: {
				page: 1,
				reverse: '',
				sort: '',
				title: ''
			  },
			component: () => import('../views/banner/bannerDetail'),
		},
		{
			path: '/merchant-list',
			name: 'merchant-list',
			query: {
				page: 1,
				reverse: '',
				sort: '',
				name: '',
				phone: '',
				salon: ''
			  },
			component: () => import('../views/merchant/merchantList'),
		},
		{
			path: '/merchant-create',
			name: 'merchant-create',
			component: () => import('../views/merchant/merchantCreate'),
		},
		{
			path: '/merchant-edit',
			name: 'merchant-edit',
			query: {
				page: 1,
				reverse: '',
				sort: '',
				name: '',
				phone: '',
				salon: ''
			  },
			component: () => import('../views/merchant/merchantEdit'),
		},
		{
			path: '/contract-list',
			name: 'contract-list',
			query: {
				page: 1,
				reverse: '',
				sort: '',
				name: '',
				phone: '',
				salon: ''
			  },
			component: () => import('../views/contract/contractList'),
		},
		{
			path: '/contract-create',
			name: 'contract-create',
			component: () => import('../views/contract/contractCreate'),
		},
		{
			path: '/contract-edit',
			name: 'contract-edit',
			query: {
				page: 1,
				reverse: '',
				sort: '',
				name: '',
				phone: '',
				salon: ''
			  },
			component: () => import('../views/contract/contractEdit'),
		},
		{
			path: '/shop-list',
			name: 'shop-list',
			query: {
				page: 1,
				reverse: '',
				sort: '',
				name: '',
				phone: '',
				address: ''
			  },
			component: () => import('../views/shop/shopList'),
		},
		{
			path: '/shop-create',
			name: 'shop-create',
			component: () => import('../views/shop/shopCreate'),
		},
		{
			path: '/shop-edit',
			name: 'shop-edit',
			query: {
				page: 1,
				reverse: '',
				sort: '',
				name: '',
				phone: '',
				address: ''
			  },
			component: () => import('../views/shop/shopEdit'),
		},
		{
			path: '/category-list',
			name: 'category-list',
			query: {
				page: 1,
				reverse: '',
				sort: '',
				name: ''
			  },
			component: () => import('../views/category/categoryList'),
		},
		{
			path: '/category-create',
			name: 'category-create',
			component: () => import('../views/category/categoryCreate'),
		},
		{
			path: '/category-edit',
			name: 'category-edit',
			query: {
				page: 1,
				reverse: '',
				sort: '',
				name: ''
			  },
			component: () => import('../views/category/categoryEdit'),
		},
		{
			path: '/point-list',
			name: 'point-list',
			query: {
				page: 1,
				reverse: '',
				sort: ''
			  },
			component: () => import('../views/point/pointList'),
		},
		{
			path: '/point-create',
			name: 'point-create',
			component: () => import('../views/point/pointCreate'),
		},
		{
			path: '/product-list',
			name: 'product-list',
			query: {
				page: 1,
				reverse: '',
				sort: '',
				name: ''
			  },
			component: () => import('../views/product/productList'),
		},
		{
			path: '/product-draft-list',
			name: 'product-draft-list',
			query: {
				page: 1,
				reverse: '',
				sort: '',
				name: ''
			  },
			component: () => import('../views/product/productDraftList'),
		},
		{
			path: '/product-pending-list',
			name: 'product-pending-list',
			query: {
				page: 1,
				reverse: '',
				sort: '',
				name: ''
			  },
			component: () => import('../views/product/productPendingList'),
		},
		{
			path: '/product-active-list',
			name: 'product-active-list',
			query: {
				page: 1,
				reverse: '',
				sort: '',
				name: ''
			  },
			component: () => import('../views/product/productActiveList'),
		},
		{
			path: '/product-inactive-list',
			name: 'product-inactive-list',
			query: {
				page: 1,
				reverse: '',
				sort: '',
				name: ''
			  },
			component: () => import('../views/product/productInactiveList'),
		},
		{
			path: '/product-reject-list',
			name: 'product-reject-list',
			query: {
				page: 1,
				reverse: '',
				sort: '',
				name: ''
			  },
			component: () => import('../views/product/productRejectList'),
		},
		{
			path: '/product-create',
			name: 'product-create',
			component: () => import('../views/product/productCreate'),
		},
		{
			path: '/product-edit',
			name: 'product-edit',
			query: {
				page: 1,
				reverse: '',
				sort: '',
				name: ''
			  },
			component: () => import('../views/product/productEdit'),
		},
		{
			path: '/bank-transfer-list',
			name: 'bank-transfer-list',
			query: {
				page: 1,
				reverse: '',
				sort: '',
				salonId: '',
				fromDate: '',
				toDate: '',
				status: ''
			  },
			component: () => import('../views/saleorder/bankTransferList'),
		},
		{
			path: '/sale-order-list',
			name: 'sale-order-list',
			query: {
				page: 1,
				reverse: '',
				sort: '',
				salonId: '',
				fromDate: '',
				toDate: '',
				status: ''
			  },
			component: () => import('../views/saleorder/saleOrderList'),
		},
		{
			path: '/sale-order-detail',
			name: 'sale-order-detail',
			query: {
				page: 1,
				reverse: '',
				sort: '',
				salonId: '',
				fromDate: '',
				toDate: '',
				status: ''
			  },
			component: () => import('../views/saleorder/saleOrderDetail'),
		},
		{
			path: '/sale-order-detail-list',
			name: 'sale-order-detail-list',
			query: {
				page: 1,
				reverse: '',
				sort: '',
				salonId: '',
				fromDate: '',
				toDate: '',
				status: ''
			  },
			component: () => import('../views/saleorder/saleOrderDetailList'),
		},
		{
			path: '/salon-revenue-billing',
			name: 'salon-revenue-billing',
			query: {
				page: 1,
				reverse: '',
				offset: '',
				salonId: '',
				saleId: '',
				reportStatus: '',
				orderStatus: '',
				isRegister: '',
				discountType: '',
				fromDate: '',
				toDate: '',
				reportDate: '',
				billingDate: '',
			  },
			component: () => import('../views/salonrevenue/salonRevenueBilling'),
		},



		]
	},

	{
		path: '/*',
		redirect: '/dashboard'
	}
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})


export default router