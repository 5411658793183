import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import env from '../enviorments'
import header from '../header'
Vue.use(Vuex)
export default new Vuex.Store({
    state: {
        token: null,
        user: null,
    },
    getters: {

    },
    mutations: {
        mutateUser(state, user) {
            state.user = user
        },
        mutateToken(state, token) {
            state.token = token
        },
    },
    actions: {
        //Auth
        async userLogin({commit}, user) {
            const result = (await axios.post(env.apiUrl + 'admin-login', {...user})).data
            if (result.status == false) return {"status": "fail", "data": [], "message": result.message, "error": []}
            window.localStorage.setItem('token', JSON.stringify(result.data.token))
            result.data.password = null,
            window.localStorage.setItem('user', JSON.stringify(result.data))
            commit('mutateToken', result.token)
            commit('mutateUser', result.data)
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        //Dashboard
        async dashboardAction({commit}) {
            const result = (await axios.get(env.apiUrl + 'dashboard', {headers: header.getToken()})).data
            if (result.status == false) return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
         //Account
         async accountListAction({commit}, option) {
            const result = (await axios.get(env.apiUrl + 'account-list', {params: {...option}, headers: header.getToken()})).data
            if (result.status == false) return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result, "message": "Success", "error": []}
        },
        async permissionListAction({commit}, option) {
            const result = (await axios.get(env.apiUrl + 'permission-list', {params: {...option}, headers: header.getToken()})).data
            if (result.status == false) return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result, "message": "Success", "error": []}
        },
        async accountFieldsAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'account-fields', {...option}, {headers: header.getToken()})).data
            if (result.status == false) return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result, "message": "Success", "error": []}
        },
        async roleListAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'role-list', {...option}, {headers: header.getToken()})).data
            if (result.status == false) return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result, "message": "Success", "error": []}
        },
        async roleFieldsAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'account-fields', {...option}, {headers: header.getToken()})).data
            if (result.status == false) return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result, "message": "Success", "error": []}
        },
        async accountStoreAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'account-store', {...option}, {headers: header.getToken()})).data
            if (result.status == false) return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        async accountUpdateAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'account-update', {...option}, {headers: header.getToken()})).data
            if (result.status == false) return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        async accountDeleteAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'account-delete', {...option}, {headers: header.getToken()})).data
            if (result.status == false) return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
},
        //Member
        async memberListAction({commit}, option) {
            const result = (await axios.get(env.apiUrl + 'member-list', {params: {...option}, headers: header.getToken()})).data
            if (result.status == false) return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result, "message": "Success", "error": []}
        },
        async memberFieldsAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'member-fields', {...option}, {headers: header.getToken()})).data
            if (result.status == false) return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result, "message": "Success", "error": []}
        },
        async memberStoreAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'member-store', {...option}, {headers: header.getToken()})).data
            if (result.status == false) return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        async memberUpdateAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'member-update', {...option}, {headers: header.getToken()})).data
            if (result.status == false) return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        async memberDeleteAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'member-delete', {...option}, {headers: header.getToken()})).data
            if (result.status == false) return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        //Subscriber
        async subscriberListAction({commit}, option) {
            const result = (await axios.get(env.apiUrl + 'subscriber-list', {params: {...option}, headers: header.getToken()})).data
            if (result.status == false) return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result, "message": "Success", "error": []}
        },
        //Banner
         async bannerListAction({commit}, option) {
            const result = (await axios.get(env.apiUrl + 'banner-list', {params: {...option}, headers: header.getToken()})).data
            if (result.status == false) return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result, "message": "Success", "error": []}
        },
        async bannerFieldsAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'banner-fields', {...option}, {headers: header.getToken()})).data
            if (result.status == false) return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        async bannerStoreAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'banner-store', {...option}, {headers: header.getToken()})).data
            if (result.status == false) return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        async bannerUpdateAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'banner-update', {...option}, {headers: header.getToken()})).data
            if (result.status == false) return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        async bannerDeleteAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'banner-delete', {...option}, {headers: header.getToken()})).data
            if (result.status == false) return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        async bannerDetailListAction({commit}, option) {
            const result = (await axios.get(env.apiUrl + 'banner-detail-list', {params: {...option}, headers: header.getToken()})).data
            if (result.status == false) return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result, "message": "Success", "error": []}
        },
        async bannerDetailStoreAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'banner-detail-store', {...option}, {headers: header.getToken()})).data
            if (result.status == false) return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        async bannerDetailUpdateAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'banner-detail-update', {...option}, {headers: header.getToken()})).data
            if (result.status == false) return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        async bannerDetailDeleteAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'banner-detail-delete', {...option}, {headers: header.getToken()})).data
            if (result.status == false) return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        //Merchant
        async merchantListAction({commit}, option) {
            const result = (await axios.get(env.apiUrl + 'merchant-list', {params: {...option}, headers: header.getToken()})).data
            if (result.status == false) return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result, "message": "Success", "error": []}
        },
        async merchantFieldsAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'merchant-fields', {...option}, {headers: header.getToken()})).data
            if (result.status == false) return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        async merchantStoreAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'merchant-store', {...option}, {headers: header.getToken()})).data
            if (result.status == false) return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        async merchantUpdateAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'merchant-update', {...option}, {headers: header.getToken()})).data
            if (result.status == false) return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        async merchantDeleteAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'merchant-delete', {...option}, {headers: header.getToken()})).data
            if (result.status == false) return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        //Shop
        async shopListAction({commit}, option) {
            const result = (await axios.get(env.apiUrl + 'shop-list', {params: {...option}, headers: header.getToken()})).data
            if (result.status == false) return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result, "message": "Success", "error": []}
        },
        async shopFieldsAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'shop-fields', {...option}, {headers: header.getToken()})).data
            if (result.status == false) return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        async shopStoreAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'shop-store', {...option}, {headers: header.getToken()})).data
            if (result.status == false) return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        async shopUpdateAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'shop-update', {...option}, {headers: header.getToken()})).data
            if (result.status == false) return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        async shopDeleteAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'shop-delete', {...option}, {headers: header.getToken()})).data
            if (result.status == false) return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        //Contract
        async contractListAction({commit}, option) {
            const result = (await axios.get(env.apiUrl + 'contract-list', {params: {...option}, headers: header.getToken()})).data
            if (result.status == false) return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result, "message": "Success", "error": []}
        },
        async contractStoreAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'contract-store', {...option}, {headers: header.getToken()})).data
            if (result.status == false) return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        async contractDeleteAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'contract-delete', {...option}, {headers: header.getToken()})).data
            if (result.status == false) return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        
        //Blog
        async blogListAction({commit}, option) {
            const result = (await axios.get(env.apiUrl + 'blog-list', {params: {...option}, headers: header.getToken()})).data
            if (result.status == false) return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result, "message": "Success", "error": []}
        },
        async blogFieldsAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'blog-fields', {...option}, {headers: header.getToken()})).data
            if (result.status == false) return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        async blogStoreAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'blog-store', {...option}, {headers: header.getToken()})).data
            if (result.status == false) return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        async blogDetailAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'blog-detail', {...option}, {headers: header.getToken()})).data
            if (result.status == false) return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        async blogDeleteAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'blog-delete', {...option}, {headers: header.getToken()})).data
            if (result.status == false) return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        async blogBodyStoreAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'blog-body-store', {...option}, {headers: header.getToken()})).data
            if (result.status == false) return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        async blogBodyDeleteAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'blog-body-delete', {...option}, {headers: header.getToken()})).data
            if (result.status == false) return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        //News
        async newsListAction({commit}, option) {
            const result = (await axios.get(env.apiUrl + 'news-list', {params: {...option}, headers: header.getToken()})).data
            if (result.status == false) return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result, "message": "Success", "error": []}
        },
        async newsFieldsAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'news-fields', {...option}, {headers: header.getToken()})).data
            if (result.status == false) return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        async newsStoreAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'news-store', {...option}, {headers: header.getToken()})).data
            if (result.status == false) return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        async newsDetailAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'news-detail', {...option}, {headers: header.getToken()})).data
            if (result.status == false) return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        async newsDeleteAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'news-delete', {...option}, {headers: header.getToken()})).data
            if (result.status == false) return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        async newsBodyStoreAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'news-body-store', {...option}, {headers: header.getToken()})).data
            if (result.status == false) return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        async newsBodyDeleteAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'news-body-delete', {...option}, {headers: header.getToken()})).data
            if (result.status == false) return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        //Category
        async categoryListAction({commit}, option) {
            const result = (await axios.get(env.apiUrl + 'category-list', {params: {...option}, headers: header.getToken()})).data
            if (result.status == false) return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result, "message": "Success", "error": []}
        },
        async categoryFieldsAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'category-fields', {...option}, {headers: header.getToken()})).data
            if (result.status == false) return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        async categoryStoreAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'category-store', {...option}, {headers: header.getToken()})).data
            if (result.status == false) return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        async categoryUpdateAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'category-update', {...option}, {headers: header.getToken()})).data
            if (result.status == false) return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        async categoryDeleteAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'category-delete', {...option}, {headers: header.getToken()})).data
            if (result.status == false) return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        //Point
        async pointListAction({commit}, option) {
            const result = (await axios.get(env.apiUrl + 'point-list', {params: {...option}, headers: header.getToken()})).data
            if (result.status == false) return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result, "message": "Success", "error": []}
        },
        async pointStoreAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'point-store', {...option}, {headers: header.getToken()})).data
            if (result.status == false) return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        //Product
        async productPendingCountAction({commit}, option) {
            const result = (await axios.get(env.apiUrl + 'product-pending-count', {headers: header.getToken()})).data
            if (result.status == false) return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result, "message": "Success", "error": []}
        },
        async productListAction({commit}, option) {
            const result = (await axios.get(env.apiUrl + 'product-list', {params: {...option}, headers: header.getToken()})).data
            if (result.status == false) return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result, "message": "Success", "error": []}
        },
        async productFieldsAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'product-fields', {...option}, {headers: header.getToken()})).data
            if (result.status == false) return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        async productStoreAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'product-store', {...option}, {headers: header.getToken()})).data
            if (result.status == false) return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        async productUpdateAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'product-update', {...option}, {headers: header.getToken()})).data
            if (result.status == false) return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        async productDeleteAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'product-delete', {...option}, {headers: header.getToken()})).data
            if (result.status == false) return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        async productNotiAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'product-noti', {...option}, {headers: header.getToken()})).data
            if (result.status == false) return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        //Sale Order
        async saleOrderCountAction({commit}, option) {
            const result = (await axios.get(env.apiUrl + 'sale-order-count', {headers: header.getToken()})).data
            if (result.status == false) return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result, "message": "Success", "error": []}
        },
        async bankTransferListListAction({commit}, option) {
            const result = (await axios.get(env.apiUrl + 'bank-transfer-list', {params: {...option}, headers: header.getToken()})).data
            if (result.status == false) return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result, "message": "Success", "error": []}
        },
        async saleOrderListAction({commit}, option) {
            const result = (await axios.get(env.apiUrl + 'sale-order-list', {params: {...option}, headers: header.getToken()})).data
            if (result.status == false) return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result, "message": "Success", "error": []}
        },
        async saleOrderDetailListAction({commit}, option) {
            const result = (await axios.get(env.apiUrl + 'sale-order-detail-list', {params: {...option}, headers: header.getToken()})).data
            if (result.status == false) return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result, "message": "Success", "error": []}
        },
        async saleOrderExportAction({commit}, option) {
            const result = (await axios.get(env.apiUrl + 'csv-download', {params: {...option}, headers: header.getToken()})).data
            if (result.status == false) return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result, "message": "Success", "error": []}
        },
        async saleOrderFieldsAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'sale-order-fields', {...option},{headers: header.getToken()})).data
            if (result.status == false) return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result, "message": "Success", "error": []}
        },
        async saleOrderInfoAction({commit}, option) {
            const result = (await axios.get(env.apiUrl + 'sale-order-info', {params: {...option}, headers: header.getToken()})).data
            if (result.status == false) return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result, "message": "Success", "error": []}
        },
        async saleOrderDetailAction({commit}, option) {
            const result = (await axios.get(env.apiUrl + 'sale-order-detail', {params: {...option}, headers: header.getToken()})).data
            if (result.status == false) return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result, "message": "Success", "error": []}
        },
        async saleOrderUpdateAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'sale-order-update', {...option},{headers: header.getToken()})).data
            if (result.status == false) return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result, "message": "Success", "error": []}
        },
        async saleOrderDetailUpdateAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'sale-order-detail-update', {...option},{headers: header.getToken()})).data
            if (result.status == false) return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result, "message": "Success", "error": []}
        },
        async bankTransferUpdateAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'bank-transfer-update', {...option},{headers: header.getToken()})).data
            if (result.status == false) return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result, "message": "Success", "error": []}
        },
        //Salon Revenue Billing
        async getSalonAction({commit}) {
            const result = (await axios.get(env.apiUrl + 'get-salon', {headers: header.getToken()})).data
            if (result.status == false) return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result, "message": "Success", "error": []}
        },
        async getSaleAction({commit}, option) {
            const result = (await axios.get(env.apiUrl + 'get-sale', {params: {...option}, headers: header.getToken()})).data
            if (result.status == false) return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result, "message": "Success", "error": []}
        },
        async salonRevenueBillingAction({commit}, option) {
            const result = (await axios.get(env.apiUrl + 'salon-revenue-billing', {params: {...option}, headers: header.getToken()})).data
            if (result.status == false) return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result, "message": "Success", "error": []}
        },
    },
    modules: {
    }
})
